@font-face {
  font-family: 'Poppins-Medium';
  src: url(font_path("poppins/Poppins-Medium.ttf"));
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(font_path("poppins/Poppins-Regular.ttf"));
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url(font_path("poppins/Poppins-SemiBold.ttf"));
}
